<template>
  <v-navigation-drawer class="mainMenuDrawer" v-bind:value="value" v-on:input="$emit('input', $event)" fixed temporary right mobile-breakpoint="600">
    <v-list-item>
      <v-list-item-content class="pa-0">
        <ul v-if="dealerJSON.pages.length > 0" class="main-menu pa-0">
          <li v-for="(page, idx) in topPages" :key="idx"><router-link class="pt-3 pb-3" :to="page.url_alias" target="_self">{{ page.menu_name }}</router-link></li>
        </ul>
        <div class="mt-1 mb-0 closeMainMenu-wrapper">
          <v-icon aria-hidden="true" class="ma-0 closeMainMenu" @click="$emit('toggleMainMenu')" large color="#000000">mdi-close-box</v-icon>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'MainMenu',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      dealerJSON: state => state.dealerJSON // get data from store
    }),
    topPages() {
      // filter pages array on menu_placement == 'bottom'
      var newArray = this.dealerJSON.pages.filter(item => {return item.menu_placement == 'top'});
      return newArray; // return the filtered Array for use in ul footer page url's
    }
  }
};
</script>

<style lang="scss">
.closeMainMenu-wrapper {
  .closeMainMenu {
    float: right;
  }
}
.mainMenuDrawer {
  z-index: 99 !important;
}
.v-overlay,
.mainMenuDrawer {
  margin-top: 64px;
}
.main-menu {
  list-style: none;
  li {
    border-top:1px solid #000000;
    a {
      display: block;
      text-decoration: none;
    }
  }
  li:first-child {
    border-top: none !important;
  }
}
@media only screen and (max-width:600px) {
  .mainMenuDrawer {
    width: 100% !important;
  }
}
// @media only screen and (max-width:599px) {
@media only screen and (max-width:767px) {
  .v-overlay,
  .mainMenuDrawer {
    margin-top: 56px !important;
  }
}
</style>